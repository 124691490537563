html {
    scroll-behavior: smooth;
}

body {
    background-color: var(--bg-color) !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
    color: #4e4f62;
}

* {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
    font-size: 20px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    color: #4e4f62;
}

.my_title {
    color: red;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;

    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    -webkit-animation-name: zoom;
    animation-name: zoom;
}

@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale3d(0.5, 0.5, 0.5);
        transform: scale3d(0.5, 0.5, 0.5);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes zoom {
    from {
        -webkit-transform: scale3d(0.5, 0.5, 0.5);
        transform: scale3d(0.5, 0.5, 0.5);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

:root {
    --border-color: rgb(219, 219, 219);
    --sq-orange: #ff8d2e;
    --sq-orange-hover: #fa7b14;
    --bg-color: #f5f5fb;
}

@media (max-width: 600px) {
    .disable_on_mobile {
        display: none !important;
    }
}

@media (min-width: 960px) {
    .disable_on_desktop {
        display: none !important;
    }
}

@media (max-width: 900px) {
    .disable_on_small {
        display: none !important;
    }
    .paddingOnMobile{
        padding-left: 5px;
        padding-right: 5px;
    }
}
